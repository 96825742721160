<template>
	<div class="content">
		<div class="header">
			<b>{{ notice.title }}</b>
			<div style="font-size: 14px;color: rgba(0, 0, 0, 0.45);margin-top: 18px;">发布时间：{{ notice.publicTime }}</div>
		</div>
		<div ref="content" class="msg" v-html="notice.context">

		</div>
		<div class="file-list-ctn" v-if="notice.fileLst && notice.fileLst.length > 0">
			<!-- <div class="">
				附件：
			</div> -->
			<div>
				<div v-for="(item, index) in notice.fileLst" class="file-item" style="display: flex;">
					<div style="font-size: 16px; font-weight: bold;">
						附件{{ index + 1 }}:
					</div>
					<div style="margin-left: 15px;">
						<!-- <a @click="handleDownloadFile(item)">
							{{ item.fileName }}.{{ item.fileExtensionName }}
						</a> -->
						
						<a @click="downloadFileFromUrl(item)">
							{{ item.fileName }}.{{ item.fileExtensionName }}
						</a>
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="footer">
			<div style="color: rgba(0, 0, 0, 0.45);" >
				<span style="color: rgba(0, 0, 0, 0.45);font-size: 14px;" v-if="afterData">上一篇：</span>
				<span style="color: #1890FF;cursor: pointer;" @click="goToDetail(afterData)" v-if="afterData">{{afterData.titleName}}</span>
			</div>
			<div style="color: rgba(0, 0, 0, 0.45);" v-if="notice.hasNext">
				<span style="color: rgba(0, 0, 0, 0.45);font-size: 14px;">下一篇：</span>
				<span style="color: #1890FF;cursor: pointer;"  @click="goToDetail(lastData)">{{lastData.titleName}}</span>
			</div>
		</div> -->
		<div class="footer">
			<div style="color: rgba(0, 0, 0, 0.45);">
				<span style="color: rgba(0, 0, 0, 0.45);font-size: 14px;" v-if="notice.previousNoticeId !== 0">上一篇：</span>
				<span style="color: #1890FF;cursor: pointer;" @click="goPrev">{{ notice.previousNoticeTitle }}</span>
			</div>
			<div style="color: rgba(0, 0, 0, 0.45);" v-if="notice.nextNoticeId !== 0">
				<span style="color: rgba(0, 0, 0, 0.45);font-size: 14px;">下一篇：</span>
				<span style="color: #1890FF;cursor: pointer;" @click="goNext">{{ notice.nextNoticeTitle }}</span>
			</div>
		</div>
	</div>
</template>

<script>
//import { Modal } from 'ant-design-vue';
import {
	getsignnotice,
	//querynoticepagelist
} from '../../api/anotherIndex';
import {
	//downLoadNoticeFile,
	readNotice,
	getNotificationFileDownLoadUrl
} from '../../api/others';
//import store from '../../store';
import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.css';
export default {
	data() {
		return {
			noticeData: [],
			notice: {},
			currentId: this.$route.query.id,
			afterData: [],
			lastData: [],
			viewer: null
		}
	},
	created() {
		let id = this.$route.query.id
		this.getsignnotice(id)

	},
	mounted() {

	},
	methods: {
		async getsignnotice(id) {
			const res = await getsignnotice({
				NoticeId: id
			})
			if (res.success) {
				this.notice = res.data
				this.currentId = this.$route.query.id
				if (res.data.hasRead === 2) {
					readNotice({
						NoticeId: this.$route.query.id
					})
				}

				this.$nextTick(() => {
					if (!this.viewer) { 
						this.viewer = new Viewer(this.$refs.content)
					}
					else {
						this.viewer.update();
					}
					//this.viewer.show()
				})


				/* const res1 = await querynoticepagelist("?PageNum=1&PageSize=9999")
				// this.total =
				
				if(res1.data.success){
					res1.data.data.data.map((n,i,item) => {
						if (n.noticeId == this.currentId) {
							this.lastData = item[i+1]
							this.afterData = item[i-1]
						}
					})
				} */

			}

		},
		goToDetail(data) {
			this.$router.push({ path: 'systemNoticeDetail', query: { id: data.noticeId } })
			let id = data.noticeId
			//let datas = '?NoticeId=' + id
			this.getsignnotice(id);

		},
		/*
		// 流下载（已弃用）
		 async handleDownloadFile(item) {

			store.commit('updateSpin', true)
			await downLoadNoticeFile({
				FileId: item.id,
			}).then((res) => {
				const blob = new Blob([res.data], {
					type: 'application/octet-stream'
				}); //处理文档流
				const fileName = `${item.fileName}.${item.fileExtensionName}`;
				const elink = document.createElement('a');
				elink.download = fileName;
				elink.style.display = 'none';
				elink.href = URL.createObjectURL(blob);
				document.body.appendChild(elink);
				elink.click();
				URL.revokeObjectURL(elink.href); // 释放URL 对象
				document.body.removeChild(elink);
			}).catch((error) => {
				Modal.error({
					title: '错误',
					content: typeof error === 'string' ? error : JSON.stringify(error),
					cancelText: ''
				});
			});
			store.commit('updateSpin', false)
		}, */
		goPrev() {
			let id = this.notice.previousNoticeId
			this.$router.push({ path: 'systemNoticeDetail', query: { id: id } });
			this.getsignnotice(id)
		},
		goNext() {
			let id = this.notice.nextNoticeId
			this.$router.push({ path: 'systemNoticeDetail', query: { id: id } });
			this.getsignnotice(id)
		},
		async downloadFileFromUrl(file) {
			this.$store.commit('updateSpin',true)
			const res = await getNotificationFileDownLoadUrl({
				FileId: file.id
			});
			if(res.success) {
				const fileName = `${file.fileName}.${file.fileExtensionName}`;
				const elink = document.createElement('a');
                elink.download = fileName;
                elink.style.display = 'none';
                elink.href = res.data;
				//elink.target = "_blank";
                document.body.appendChild(elink);
                elink.click();
                //URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
			}
			this.$store.commit('updateSpin',false)
		}
	}
}
</script>

<style scoped>
.header {
	height: 98px;
	width: 97%;
	border-bottom: 1px solid #EBEBEB;
	margin: 0 auto;
	color: rgba(0, 0, 0, 0.85);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	font-weight: 500
}

.content {
	width: 98%;
	border-radius: 2px;
	min-height: 808px;
	background: #FFFFFF;
	margin: 0 auto;
	margin-bottom: 16px;
	margin-top: 16px;
	font-size: 14px;
	display: flex;
	flex-direction: column;
}

.msg {
	min-height: calc(100vh - 420px);
	flex-grow: 1;
	border-bottom: 1px solid #EBEBEB;
	width: 97%;
	margin: 0 auto;
	padding: 16px 0;
	color: rgba(0, 0, 0, 0.85);
	text-align: left;
}

.footer {
	display: flex;
	justify-content: space-between;
	margin: 0 104px;
	margin-top: 16px;
	margin-bottom: 16px;
}

.file-list-ctn {
	display: flex;
	text-align: left;
	color: rgba(0, 0, 0, 0.85);
	padding: 16px 50px 16px 32px;
	border-bottom: 1px solid #eee;

}
</style>

<style lang="less" scoped>
.file-item+.file-item {
	margin-top: 8px;
}

.content {
	box-shadow: @srims-primary-box-shadow-light;
	border-radius: 4px;
}
</style>
